$default-transition: all 0.3s ease;
$fontFamily: "Poppins", sans-serif;
$fontSpaceMono: "Space Mono", sans-serif;
$fontSizeSmaller: 0.75rem;
$fontSizeSmall: 0.875rem;
$fontSizeDefault: 1rem;
$fontSizeTitle: 1.5rem;
$fontSizeTitleLg: 1.75rem;
$fontSizeLg: 2rem;
$fontWeightMedium: 600;
$fontSizeS: 16px;

$white: #ffffff;
$black: #000000;
$almostBlack: #171717;
$oldTransparentOrange: rgba(255, 140, 44, 0.1);
$transparentOrange: rgba(254, 100, 70, 0.1);
$lessTransparentOrange: rgba(254, 100, 70, 0.2);
$orangeLight: #fec146;
$orange: #fe6446;
$orangeDark: #e53a1f;
$gray: #acacac;
$darkestGray: #222c39;

$black: #000000;
$transparentBlack: rgba(0, 0, 0, 0.4);
$white: #ffffff;
$transparentRed: rgba(196, 0, 0, 0.1);
$red: #c40000;
$lightRed: #f72424;
$blueishGrayVeryLight: #f7f8f9;
$blueishGray: #867fa0;
$grayAlmostAlmostWhite: #ffffff;
$grayAlmostWhite: #fafafa;
$grayVeryVeryLight: #f8f8f8;
$grayVeryLight: #f2f2f2;
$grayBackground: #f4f6f8;
$grayLight: #dbdbdb;
$gray: #bcbcbc;
$grayDark: #909090;
$grayDarker: #6f6f6f;
$transparentPurple: rgba(71, 0, 192, 0.1);
$blue: #3162db;
$blueWeird: #353f4a;
$blueVeryLight: #ecf6fa;
$blueVeryDark: #100036;
$greenVeryVeryLight: #dff0d8;
$greenVeryLight: #d6e9c6;
$greenLight: #94d053;
$transparentGreen: rgba(99, 173, 19, 0.07);
$green: #63ad13;
$newGreen: #45dd9d;
$transparentGreen: rgba(148, 208, 83, 0.1);
$greenDark: #3c763d;
$waterGreen: #03a48c1d;
$waterGreenBorder: #2ca28e;
$darkerWaterGreen: #0aa28c;
$waterGreenText: #06a38c;
$borderGrey: #d8d8d8;
$inputDisabled: #00000040;
$placeholderFont: #8e8e8e;

$mobileMaxWidth: 599px;
$largeDevicesMinWidth: 600px;
$largeDevicesWidth: 992px;
$tabletMinWidth: 993px;
$tabletMaxWidth: 1024px;
$desktopSmallMinWidth: 1025px;
$desktopSmallMaxWidth: 1439px;
$desktopMinWidth: 1440px;
